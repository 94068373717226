.access-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
  }
  
  .access-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .access-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    padding: 20px;
    flex: 1 1 calc(50% - 20px);
    min-width: 360px;
  }
  
  .access-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .access-secret {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    font-family: monospace;
    font-size: 14px;
  }
  
  .secret-text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #d32f2f;
  }

  .secret-text-k {
    flex: 1;
    overflow: hidden;
    font-size: smaller;
    text-overflow: ellipsis;
    color: #010101;
  }
  
  .access-actions button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
  }

  .access-key-actions button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 9px;
    margin-left: 2px;
  }
  
  .table-wrapper {
    overflow-x: auto;
    margin-top: 8px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .table th,
  .table td {
    padding: 4px;
    text-align: left;
    border-top: 1px solid #eee;
  }
  
  .message-box {
    margin-top: 20px;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
  }
  
  .message-success {
    background-color: #e6f4ea;
    color: #2e7d32;
    border: 1px solid #a5d6a7;
  }
  
  .message-error {
    background-color: #fdecea;
    color: #c62828;
    border: 1px solid #f19999;
  }
  